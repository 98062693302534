<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <!-- 게시판 본문 시작-->
  <div id="board-list-view" class="data-list-container">
    <div style="text-align: center; font-weight: 800; font-size: 20px;" v-html="boardTitle"></div>
      <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <div v-if="postData" style="font-size: 13px; padding: 10px 20px;">
      <div style="font-size: 15px; font-weight: 800; font-color: black; padding: 0px 20px; background-color: rgb(180,180,180)">
        <table><tbody><tr><th>{{postData.title}}</th><th>{{postData.writer}}</th><th>{{postData.reg_dt}}</th><th>{{postData.viewed}}</th>
        </tr></tbody></table>
      </div>
      <div class="content" style="padding: 20px 20px;" v-html="postData.contents" />
      <div class="content" style="padding: 0px 20px;" :data="tr" :key="index" v-for="(tr, index) in postFiles">
        <form action='/api/board/downloadFile' method='post'>
        <input type="hidden" name="filename" :value=tr.filename />
        <input type="hidden" name="tbcode" :value=searchVar.tbcode />
        <button type="submit">{{tr.filename}}</button></form>
        <div class="content" style="padding: 0px 20px;" v-if="imageExtentions.find(e=> e == tr.filename.split('.')[1])">
          <img :src="makeImageLink(tr.filename, searchVar.tbcode)" style="max-width: 100%" />
        </div> 
      </div>
      <div v-if="replyOn == 'T'" class="content" style="padding: 0px 20px;">
        <table style="width:100%; margin: 0 auto;"><tbody>
          <tr>
            <td style="width: 80%;" colspan='2'><textarea style="width:98%" v-model="replyContents"/></td>
            <td><vs-button color="primary" type="border" class="p-2 cursor-pointer" @click="setReply(replyContents)">등록</vs-button></td>
          </tr>
          <tr :data="tr" :key="index" v-for="(tr, index) in postReplies">
            <td style="text-align: center; width: 120px;" v-html="'<b>' + tr.writer + '</b><br><br>' + tr.reg_dt" />
            <td v-html="nl2br(tr.contents)" />
            <td><vs-button v-if="activeUserInfo.level > 9 || tr.writer_idx == activeUserInfo.id" color="primary" type="border" class="p-2 cursor-pointer" @click="deleteReply(tr.idx)">삭제</vs-button></td>
          </tr>
        </tbody></table>
      </div>
      <div style="text-align: center;">
          <vs-button v-if="activeUserInfo.level > 9 || users[selectedIndex].writer_idx == activeUserInfo.id" style="margin-right: 20px;"
                    color="primary" type="border" class="p-2 cursor-pointer" @click="editPost(selectedId)">수정</vs-button>
          <vs-button v-if="activeUserInfo.level > 9 || users[selectedIndex].writer_idx == activeUserInfo.id" 
                    color="primary" type="border" class="p-2 cursor-pointer" @click="deletePost(selectedId)">삭제</vs-button>
      </div>
    </div>
  <!-- 게시판 본문 끝-->

  <!-- 게시판 리스트 시작-->
    <vs-table ref="table" :max-items="searchVar.itemsPerPage" :data="users">  <!-- multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="users">-->

      <div slot="header" class="flex flex-wrap items-center flex-grow">
        <v-select style="width: 100px; margin: 0px 5px" placeholder="기자" label="nickname" index="idx" v-model="selectedWriter"
          :options="writer"/>
        <!-- <vs-select
          placeholder="기자 선택"
          label=""
          v-model="selectedWriter"
          width="110px"
          >
          <vs-select-item :key="index" :value="item.idx" :text="item.nickname" v-for="(item,index) in writer"/>
        </vs-select> -->
        <div style="padding-top: 0; margin: 0px 5px">
          <vs-input class="inputx" placeholder="검색" v-model="searchText" @keyup.enter="searchList(searchVar)"/>
        </div>
        <div><vs-button style="margin: 0px 5px" color="primary" type="border" class="p-2 cursor-pointer" @click="searchList(searchVar)">검색</vs-button></div>
        <div><vs-button style="margin: 0px 5px" color="primary" type="border" class="p-2 cursor-pointer" @click="newPost()">글작성</vs-button></div>
        <div  id="data-list-search-rank" style="padding-top: 0;">
          <div class="p-2 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ ((currentx - 1) * searchVar.itemsPerPage) + 1 }} - {{ numOfPosts >= searchVar.itemsPerPage ? (currentx * searchVar.itemsPerPage) : numOfPosts }} of {{ numOfPosts }}</span>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th>No</vs-th>
        <vs-th>제목</vs-th>
        <vs-th>작성자</vs-th>
        <vs-th>작성시간</vs-th>
        <vs-th>View</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td><div @click="getPostRoute(tr.idx)" v-html="noText(tr.notice, tr.idx)" /></vs-td>
              <vs-td><div @click="getPostRoute(tr.idx)" v-html="titleText(tr.title, tr.re_count, tr.file_count)" /></vs-td>
              <vs-td><div @click="getPostRoute(tr.idx)" v-html="'<b>' + tr.writer + '</b>'"/></vs-td>
              <vs-td><div @click="getPostRoute(tr.idx)">{{ tr.reg_dt }}</div></vs-td>
              <vs-td><div @click="getPostRoute(tr.idx)">{{ tr.viewed }}</div></vs-td>
            </vs-tr>
          </tbody>
        </template>
    </vs-table>
    <div>
        <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div>
  <!-- 게시판 리스트 끝-->

  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      selectedIndex: 0,
      selectedId: 0,
      users: [{title: '', re_count: '', writer: ''}],
      currentx: 1,
      numOfPosts: 0,
      numOfPages: 0,
      searchVar: {
        startRow: 0,
        itemsPerPage: 20,
        tbcode: '105',
        idx: '',
        writer: null,
        searchText: '',
      },
      newPostVar:{
        postType: '1',
      },
      writerName: '',
      writerIdx: '',
      selectedWriter: null,
      writer: [],
      searchText: '',
      boardTitle: '',
      postData: '',
      replyOn: 'F',
      replyContents: '',
      viewPassword: '',
      bdPassword: '',
      postFiles: [],
      postReplies: [],
      imageExtentions: ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
      isMounted: false,
    }
  },
  watch: {
    currentx: function() {
                                    // currentx-1 is value before changed by watch: -> currentx:
      // this.searchVar.startRow = (this.currentx-1) * this.searchVar.itemsPerPage;

      // window.scrollTo(0,0);
      // this.getPostsList(this.searchVar);
      let data = {}
      for(const key of Object.keys(this.$route.query)){
        data[key] = this.$route.query[key];
      }
      data.startRow = (this.currentx-1) * this.searchVar.itemsPerPage;
      delete data.idx;
      console.log(data);
      this.$router.push({path: this.$router.path, query: data})
    },
    '$route.query': function() {
      this.created();
      // console.log(this.$route.query);
      // this.currentx = 1;
      // if(this.$route.query.tbcode) this.searchVar.tbcode = this.$route.query.tbcode;
      // if(this.$route.query.idx) this.searchVar.idx = this.$route.query.idx;
      // window.scrollTo(0,0);
      // this.getPostsList(this.searchVar);
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    getPostRoute(postId){
      let data = {}
      for(const key of Object.keys(this.$route.query)){
        data[key] = this.$route.query[key];
      }
      data.idx = postId;
      this.$router.push({path: this.$router.path, query: data})
    },
    getPost(postId){
      window.scrollTo(0,0);
      // this.postsDetailModalActive = true;
      var bdPassword = '';
      if(this.viewPassword == 'T') bdPassword = prompt('비밀번호를 입력하세요');
      
      this.selectedIndex = this.users.findIndex(e => e.idx == postId);
      this.selectedId = postId;
      console.log(this.selectedIndex, this.selectedId);
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/board/getPost', {'idx': postId, 'tbcode': this.searchVar.tbcode, 'password': bdPassword})
        .then(function (response) {
          // console.log(response.data);
          if(response.data == 'Incorrect password!'){
            alert('비밀번호가 틀렸습니다.');
          }else{
            thisIns.postFiles = response.data[1];
            thisIns.postReplies = response.data[2];
            thisIns.postData = response.data[0][0];
          }
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    setReply(contents){ 
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/board/setReply', {'idx': this.selectedId, 'tbcode': this.searchVar.tbcode, 'contents': contents,
                                              'writer': this.writerName, 'writer_idx': this.writerIdx})
        .then(function () {
          // console.log(response)
          thisIns.$vs.notify({
            title:'성공',
            text: '댓글 등록 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.replyContents = '';
          thisIns.getPost(thisIns.selectedId);
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    deleteReply(replyId){
      console.log(replyId);  
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/board/deleteReply', {'idx': replyId, 'tbcode': this.searchVar.tbcode})
        .then(function () {
          // console.log(response)
          thisIns.$vs.notify({
            title:'성공',
            text: '댓글 삭제 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.replyContents = '';
          thisIns.getPost(thisIns.selectedId);
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    noText(notice, idx){
      if(notice == 2) return "<label style='color:#cc0066; font-weight:900'>공지</label>";
      else return idx;
    },
    titleText(title, re_count, file_count){
      var titleText = '<font color=darkblue><b>' + title + '</font>';
      if(file_count > 0) titleText += '<i class="material-icons" style="font-size: 14px;">attach_file</i>';
      if(re_count > 0) titleText += ' <font color=red><b>(' + re_count + ')</b></font>';
      return titleText
    },
    nl2br(text){
      return text.replace(/\n/g, '<br>');
    },
    newPost(){
      // this.newPostModalActive = true;
      this.$router.push({path: '/writePost', query: {tbcode: this.searchVar.tbcode}});
    },
    editPost(postId){
      // this.newPostModalActive = true;
      this.$router.push({path: '/writePost', query: {tbcode: this.searchVar.tbcode, idx: postId}});
    },
    makeImageLink(filename, tbcode){
      return "/api/board/viewImages?filename=" + filename + "&tbcode=" + tbcode;
    },
    deletePost(postId){
      console.log("delete: " + postId);
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/deletePost', {'idx': postId, 'tbcode': this.searchVar.tbcode})
        .then(function (res) {
          thisIns.$vs.notify({
            title:'성공',
            text: '삭제 성공',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
            console.log(res);
            thisIns.getPostsList(thisIns.searchVar);
            thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();
        });
    },
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum
      this.getPostsList(this.searchVar)
    },
    searchList(data){
      data.startRow = 0;
      this.currentx = 1;
      data.writer = this.selectedWriter;
      data.searchText = this.searchText;
      this.getPostsList(data);
    },
    getPostsList(data) {
      console.log('테이블선택: ', data.tbcode)
      if(data.tbcode == 103 && this.activeUserInfo.level < 8){
        alert('권한이 없습니다.')
        this.$router.go(-1)
      }
      this.postData = '';
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/getList', data)
        .then(function (response) {
          console.log(response);
          if(response.data[0][0].posts_count){
            thisIns.numOfPosts = response.data[0][0].posts_count;
            thisIns.numOfPages = parseInt(response.data[0][0].posts_count / thisIns.searchVar.itemsPerPage);
            thisIns.users = response.data[1];
          }else{
            thisIns.numOfPosts = 0;
            thisIns.numOfPages = 0;
            thisIns.users = [{title: '', re_count: ''}];
          }
          thisIns.boardTitle = response.data[2].tbtitle;
          thisIns.replyOn = response.data[2].reple;
          thisIns.viewPassword = response.data[2].view_password;
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    created(){
      this.writer = this.$store.state.writer;
      this.writerName = this.$store.state.AppActiveUser.name;
      this.writerIdx = this.$store.state.AppActiveUser.id;
      if(this.$route.query.tbcode) this.searchVar.tbcode = this.$route.query.tbcode;
      this.searchVar.startRow = this.$route.query.startRow * 1 || 0;
      console.log(this.searchVar)
      this.getPostsList(this.searchVar);
      if(this.$route.query.idx){
        this.searchVar.idx = this.$route.query.idx;
        this.getPost(this.searchVar.idx);
      }
    },
    getOrderStatusColor(status) {
      if(status == 'on hold') return "warning"
      if(status == 'delivered') return "success"
      if(status == 'canceled') return "danger"
      return "primary"
    },
    getPopularityColor(num) {
      if(num > 90) return "success"
      if(num >70) return "primary"
      if(num >= 50) return "warning"
      if(num < 50) return "danger"
      return "primary"
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    this.created();
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-board.scss";
</style>
